import React, { Component } from "react";
import {
  Form,
  Header,
  Message,
  GridRow,
  Segment,
  Grid,
  GridColumn,
  Button,
  Label,
} from "semantic-ui-react";
import moment from "moment";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import client from "../../../shared/feathers";

class CustomerEdit extends Component {
  state = {
    saving: false,
    birthdate: "",
    error: false,
    success: false,
  };
  save() {
    this.setState({ saving: true, success: false });
    let {
      intern,
      sku,
      product_name,
      channel,
      salesline,
      department,
      format,
      description,
      unit,
      single_price,
      unit2,
      amount,
      surcharge,
      discount,
    } = this.state;
    if (
      intern ||
      sku ||
      product_name ||
      channel ||
      salesline ||
      department ||
      format ||
      description ||
      unit ||
      single_price ||
      unit2 ||
      amount ||
      surcharge ||
      discount
    ) {
      client
        .service("products")
        .patch(this.props.customer.id, {
          intern,
          sku,
          product_name,
          channel,
          salesline,
          department,
          format,
          description,
          unit,
          single_price,
          unit2,
          amount,
          surcharge,
          discount,
        })
        .then((result) => {
          this.setState({ saving: false, error: false, success: true });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ saving: false, error: true });
        });
    } else {
      this.setState({ saving: false, error: false, success: true });
    }
  }
  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  render() {
    let customer = this.props.customer;
    return (
      <Form
        loading={this.state.saving}
        size="small"
        onSubmit={this.save.bind(this)}
      >
        {this.state.error && (
          <Message negative>
            Fehler beim speichern der Informationen. Bitte überprüfe alle
            Felder!
          </Message>
        )}
        {this.state.success && (
          <Message positive>Erfolgreich gespeichert!</Message>
        )}
        <Grid>
          <GridRow columns="equal">
            <Grid.Column>
              <Segment.Group>
                <Header attached="top">Allgemeine Informationen</Header>
                <Segment attached="bottom" color="green">
                  <Form.Group widths="equal">
                    <Form.Input
                      defaultValue={this.props.customer.sku}
                      name="sku"
                      width={4}
                      onChange={this.handleChange.bind(this)}
                      required
                      label="Produkt-ID"
                    />
                    <Form.Input
                      defaultValue={this.props.customer.product_name}
                      name="product_name"
                      list="product_name"
                      onChange={this.handleChange.bind(this)}
                      required
                      label="Produktname"
                    />
                    <datalist id="product_name">
                      <option value="Sponsored Product Ads">
                        Sponsored Product Ads
                      </option>
                      <option value="Commerce Display">Commerce Display</option>
                      <option value="Display Ads">Display Ads</option>
                      <option value="Audience Extension">
                        Audience Extension
                      </option>
                      <option value="Sonderflächen">Sonderflächen</option>
                      <option value="Handling Fee">Handling Fee</option>
                      <option value="Newsletter">Newsletter</option>
                      <option value="Print Mailing">Print Mailing</option>
                      <option value="Paket-Beileger">Paket-Beileger</option>
                      <option value="Kassen-Beileger">Kassen-Beileger</option>
                      <option value="Pop Up Space">Pop Up Space</option>
                      <option value="OOH Fassade">OOH Fassade</option>
                      <option value="DOOH Instore">DOOH Instore</option>
                      <option value="DOOH Schaufenster">
                        DOOH Schaufenster
                      </option>
                      <option value="Instore Deko">Instore Deko</option>
                      <option value="Promotion Artikel">
                        Promotion Artikel
                      </option>
                      <option value="Facebook">Facebook</option>
                      <option value="Instagram">Instagram</option>
                      <option value="Blog">Blog</option>
                      <option value="Influencer">Influencer</option>
                      <option value="Event">Event</option>
                      <option value="Sonstiges">Sonstiges</option>
                    </datalist>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Select
                      defaultValue={this.props.customer.channel}
                      name="channel"
                      options={[
                        { key: 1, text: "Online", value: "Online" },
                        { key: 2, text: "Direct", value: "Direct" },
                        { key: 3, text: "Store", value: "Store" },
                        { key: 4, text: "Social Media", value: "Social Media" },
                        { key: 5, text: "Event", value: "Event" },
                        { key: 6, text: "Analytics", value: "Analytics" },
                        { key: 7, text: "Sonstiges", value: "Sonstiges" },
                      ]}
                      onChange={this.handleChange.bind(this)}
                      required
                      label="Kanal"
                    />
                    <Form.Select
                      defaultValue={this.props.customer.salesline}
                      name="salesline"
                      options={[
                        { key: 1, text: "SportScheck", value: "SportScheck" },
                        { key: 2, text: "Galeria", value: "Galeria" },
                        { key: 3, text: "kika", value: "kika" },
                        { key: 4, text: "Leiner", value: "Leiner" },
                        { key: 5, text: "DFL", value: "DFL" },
                        {
                          key: 6,
                          text: "Galeria INTERN",
                          value: "Galeria INTERN",
                        },
                        { key: 7, text: "Keine", value: "" },
                        { key: 8, text: "POOL", value: "POOL" },
                        { key: 9, text: "EMSU", value: "EMSU" },
                        { key: 10, text: "EDEKA (Viewento)", value: "EDEKA (Viewento)" },
                        { key: 11, text: "Fashionette", value: "Fashionette" },
                      ]}
                      onChange={this.handleChange.bind(this)}
                      required
                      label="Vertriebslinie"
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Select
                      defaultValue={this.props.customer.department}
                      name="department"
                      options={[
                        { key: 1, text: "Keine", value: "--" },
                        {
                          key: 2,
                          text: "Alle Filialen",
                          value: "Alle Filialen",
                        },
                        { key: 3, text: "Region Süd", value: "Region Süd" },
                        { key: 4, text: "Region Mitte", value: "Region Mitte" },
                        { key: 5, text: "Region Nord", value: "Region Nord" },
                        { key: 6, text: "Aachen", value: "Aachen" },
                        { key: 7, text: "Augsburg", value: "Augsburg" },
                        {
                          key: 8,
                          text: "Berlin (Schöneberg)",
                          value: "Berlin (Schöneberg)",
                        },
                        {
                          key: 9,
                          text: "Berlin (Mitte)",
                          value: "Berlin (Mitte)",
                        },
                        {
                          key: 10,
                          text: "Berlin (Steglitz)",
                          value: "Berlin (Steglitz)",
                        },
                        { key: 11, text: "Bielefeld", value: "Bielefeld" },
                        { key: 12, text: "Bonn", value: "Bonn" },
                        {
                          key: 13,
                          text: "Braunschweig",
                          value: "Braunschweig",
                        },
                        { key: 14, text: "Bremen", value: "Bremen" },
                        { key: 15, text: "Dortmund", value: "Dortmund" },
                        {
                          key: 16,
                          text: "Dresden (Altmarkt-Galerie)",
                          value: "Dresden (Altmarkt-Galerie)",
                        },
                        {
                          key: 17,
                          text: "Dresden (Centrum-Galerie)",
                          value: "Dresden (Centrum-Galerie)",
                        },
                        { key: 18, text: "Erfurt", value: "Erfurt" },
                        { key: 19, text: "Frankfurt", value: "Frankfurt" },
                        { key: 20, text: "Göttingen", value: "Göttingen" },
                        {
                          key: 21,
                          text: "Hamburg (Altstadt)",
                          value: "Hamburg (Altstadt)",
                        },
                        {
                          key: 22,
                          text: "Hamburg (Harburg)",
                          value: "Hamburg (Harburg)",
                        },
                        {
                          key: 23,
                          text: "Hannover (Mitte)",
                          value: "Hannover (Mitte)",
                        },
                        {
                          key: 24,
                          text: "Hannover (Osterstraße)",
                          value: "Hannover (Osterstraße)",
                        },
                        { key: 25, text: "Heidelberg", value: "Heidelberg" },
                        { key: 26, text: "Karlsruhe", value: "Karlsruhe" },
                        { key: 27, text: "Kassel", value: "Kassel" },
                        { key: 28, text: "Köln", value: "Köln" },
                        { key: 29, text: "Konstanz", value: "Konstanz" },
                        { key: 30, text: "Leipzig", value: "Leipzig" },
                        { key: 31, text: "Magdeburg", value: "Magdeburg" },
                        {
                          key: 32,
                          text: "Mönchengladbach",
                          value: "Mönchengladbach",
                        },
                        { key: 33, text: "München", value: "München" },
                        { key: 34, text: "Münster", value: "Münster" },
                        { key: 35, text: "Nürnberg", value: "Nürnberg" },
                        { key: 36, text: "Stuttgart", value: "Stuttgart" },
                        { key: 37, text: "Wildau", value: "Wildau" },
                        {
                          key: 38,
                          text: "München (Am Marienplatz)",
                          value: "München (Am Marienplatz)",
                        },
                        {
                          key: 39,
                          text: "Oberhausen (CentrO)",
                          value: "Oberhausen (CentrO)",
                        },
                      ]}
                      onChange={this.handleChange.bind(this)}
                      required
                      label="Filiale"
                    />
                    <Form.Input
                      defaultValue={this.props.customer.format}
                      required
                      name="format"
                      onChange={this.handleChange.bind(this)}
                      label="Format / Fläche"
                    />
                  </Form.Group>
                  <Form.TextArea
                    defaultValue={this.props.customer.description}
                    name="description"
                    onChange={this.handleChange.bind(this)}
                    label="Beschreibung"
                  />
                </Segment>
              </Segment.Group>
            </Grid.Column>

            <Grid.Column>
              <Segment.Group>
                <Header attached="top">Preise</Header>
                <Segment attached="bottom" color="green">
                  <Form.Group widths="equal">
                    <Form.Select
                      defaultValue={this.props.customer.unit}
                      required
                      label="Mengen-Einheit"
                      options={[
                        { key: 1, value: "pauschal", text: "pauschal" },
                        { key: 2, value: "AI", text: "AI" },
                        { key: 3, value: "KW", text: "KW" },
                        { key: 4, value: "Clicks", text: "Clicks" },
                        { key: 5, value: "Kontakte", text: "Kontakte" },
                        { key: 6, value: "Beilagen", text: "Beilagen" },
                        { key: 7, value: "Post", text: "Post" },
                      ]}
                      onChange={this.handleChange.bind(this)}
                      name="unit"
                    />
                    <Form.Input
                      label="Einzelpreis"
                      name="single_price"
                      required
                      children={
                        <MaskedInput
                          defaultValue={this.props.customer.single_price}
                          mask={createNumberMask({
                            prefix: "",
                            thousandsSeparatorSymbol: "",
                            suffix: "",
                            allowDecimal: true,
                          })}
                          onChange={(e) =>
                            this.handleChange(e, {
                              name: "single_price",
                              value: e.target.value,
                            })
                          }
                          name="single_price"
                          required
                        />
                      }
                    />
                    <Form.Select
                      defaultValue={this.props.customer.unit2}
                      required
                      label="Preis-Einheit"
                      options={[
                        { key: 1, value: "pauschal", text: "pauschal" },
                        { key: 3, value: "KW", text: "KW" },
                        { key: 4, value: "CPC", text: "CPC" },
                        { key: 5, value: "Jahr", text: "Jahr" },
                        { key: 6, value: "TKP", text: "TKP" },
                        { key: 7, value: "Post", text: "Post" },
                      ]}
                      onChange={this.handleChange.bind(this)}
                      name="unit2"
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input
                      defaultValue={this.props.customer.amount}
                      required
                      label="Menge / Reichweite"
                      type="number"
                      onChange={this.handleChange.bind(this)}
                      name="amount"
                    />
                    <Form.Input
                      defaultValue={this.props.customer.surcharge}
                      required
                      label="Aufschlag"
                      type="number"
                      onChange={this.handleChange.bind(this)}
                      name="surcharge"
                    />
                    <Form.Input
                      defaultValue={this.props.customer.discount}
                      required
                      label="Rabatt"
                      type="number"
                      onChange={this.handleChange.bind(this)}
                      name="discount"
                    />
                  </Form.Group>
                  <Form.TextArea
                    defaultValue={this.props.customer.intern}
                    name="intern"
                    onChange={this.handleChange.bind(this)}
                    label="Interne Notiz"
                  />
                </Segment>
              </Segment.Group>
              <Form.Button color="green" fluid>
                Speichern
              </Form.Button>
            </Grid.Column>
          </GridRow>
        </Grid>
      </Form>
    );
  }
}

export default CustomerEdit;
